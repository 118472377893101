$search-popup-header-size: 60px;
$icon-path: '../../../public/static/images/new_search/icons/icon-filters.svg';

.new-search-popup {
  overscroll-behavior-y: none;

  &__wrapper {
    padding: 0 15px;
  }

  &--filters:not(#{&}--is-focused) {
    background-color: $colour-grey-desert-storm;
  }

  &__no-border {
    border-bottom: 0 !important;
  }

  &__header {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid $colour-grey-wild-sand;
    background-color: $colour-white;
    height: $search-popup-header-size;
    z-index: 99;
    // stylelint-disable-next-line
    -webkit-backface-visibility: hidden;

    @include mq(480) {
      height: $search-popup-header-size;
    }

    &-title {
      margin: 0;
      font-size: get-vw(16px, 375);
      font-weight: 500;
      text-align: center;

      @include mq(480) {
        font-size: 16px;
      }
    }

    &-subtitle {
      margin: 0;
      color: #98918a;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      text-transform: lowercase;
    }

    &-action {
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;

      &__right {
        right: 5px;
        position: absolute;
        height: 100%;
      }
    }

    &-btn {
      vertical-align: middle;
      height: 100%;
      padding: 0 get-vw(15px, 375);
      white-space: nowrap;
      background: transparent;
      text-align: left;
      font-family: $helvetica;
      font-size: 15px;
      font-weight: 400;

      @include mq(480) {
        padding: 0 15px;
        font-size: 15px;
      }

      &.disabled {
        color: #98918a !important;
        pointer-events: none;
      }

      &--close {
        width: $search-popup-header-size;
        height: $search-popup-header-size;
        padding: 0;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 0;
        background-repeat: no-repeat;
        background-position: left 15px center;
        background-size: get-vw(15px, 375);
        @include arrow(12px, $colour-heathered-grey, 2px) {
          transform: rotate(135deg);
          margin-left: 15px;
        }
      }
    }

    @include mq(480) {
      height: $search-popup-header-size;

      &-title {
        font-size: 16px;
      }

      &-btn {
        padding: 0 15px;

        &_text {
          font-size: 15px;
        }

        &--close {
          width: $search-popup-header-size;
          height: $search-popup-header-size;
          padding: 0;
          background-size: 15px;
        }
      }
    }
  }

  &__content {
    height: 100%;
    -webkit-overflow-scrolling: touch;

    @include mq(480) {
      padding-top: $search-popup-header-size;
    }

    .is-hidden + & {
      padding-top: 0;
    }

    &-link,
    &-btn {
      color: $colour-cocoa-brown;
      display: inline-block;
      padding: 10px 0 10px 10px;
      font-size: 15px;
      text-decoration: none;
      background-color: transparent;
    }
  }

  &__container {
    &--locations {
      padding-bottom: 75px;
    }
  }

  &__tools {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 75px 15px 15px;

    &:not(#{&}--is-focused) {
      .search-popup--locations & {
        border-bottom: 0;
      }
    }
  }

  &__top-tools {
    display: block;

    &-container {
      outline: none;
      background-color: $colour-white;
      border-bottom: 0.5px solid $colour-grey-wild-sand;
      border-top: 0.5px solid $colour-grey-wild-sand;
    }

    .new-search-form__city {
      display: flex;
      justify-content: space-between;
      height: 38px;
      margin-bottom: 0;
    }
  }

  &__apply-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: calc(100% - 30px);
    margin: 0 15px;
    bottom: calc(env(safe-area-inset-bottom) + 10px);
    height: 45px;
    border-radius: 14px;
    background-color: $colour-neon-blue;
    font-size: 16px;
    color: $colour-white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
    transition: opacity 0.3s ease-in-out 0.3s;

    &__hidden {
      opacity: 0;
      visibility: hidden;
      transition-delay: 0s;
    }
  }
}
