$title-color: #98918a;
$background-color: #f8f8f8;
$border-color: #eae5e1;

.display-none {
  display: none;
}

.divider {
  height: 1px;
  border-bottom: 0.5px solid $border-color;
}

.filters {
  &__overlay {
    margin-top: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    background-color: $background-color;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__content {
    background-color: $background-color !important;
  }

  &__leased-spacing {
    margin-top: 60px;
  }

  &__options-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__secondary-text {
    color: $title-color;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  &__secondary-text__number {
    color: $title-color;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 7px;
    margin-top: 20px;
  }

  &__section {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    padding: 0 15px;
    margin-bottom: 10px;
    border-bottom: 0.5px solid $border-color;
    border-top: 0.5px solid $border-color;
  }

  &__last-block {
    margin-bottom: 85px;
  }

  &__ad-delete-icon {
    transform: translate(0, -10px);
  }

  &__fields-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }

  &__text-field {
    display: block;
    width: 100%;
    padding: 0 20px 9px 0;
    height: 26px;
    font-size: 15px;
    border: 0;
    outline: none;
    border-radius: 0;
    border-bottom: 0.5px solid $border-color;
    margin-bottom: 5px;

    ::placeholder {
      color: $title-color;
      opacity: 1;
    }
  }

  &__delete-icon {
    height: 15px;
    width: 15px;
    position: absolute;
    right: 0;
    top: -2px;
  }

  &__field-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50% - 7px);
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__submit-button {
    $button: &;
    width: calc(100% - 30px);
    height: 50px;
    position: fixed;
    border-radius: 14px;
    bottom: env(safe-area-inset-bottom);
    display: block;
    margin: 15px 15px 20px;
    flex-grow: 1;
    font-size: 16px;
    text-align: center;
    color: #fff;
    background-color: #4264fd;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  }

  &__leased_type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #eae5e1;
    height: 38px;
    font-size: 15px;
    color: #2f1f19;
    margin: 15px 0;

    &-active {
      background-color: #dae8ff;
      border-color: #4264fd;
      color: #4264fd;
    }
  }

  &__search_by_id {
    color: #2f1f19;
    background-color: #fff;
    font-size: 15px;
    width: 100%;
    display: flex;
    outline: none;
    border: 0;
    align-items: center;
    justify-content: flex-start;
  }

  &__link {
    height: 100%;
    -webkit-overflow-scrolling: touch;
    display: inline-block;
    margin-bottom: 10px;
    font-size: 15px;
    color: $colour-neon-blue;
    text-decoration: none;
    background-color: transparent;
  }

  &__toggle {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 55px;
    font-size: 15px;
  }

  &__option {
    display: flex;
    width: 50%;

    &:first-child .filters__leased_type {
      border-radius: 14px 0 0 14px;
    }

    &:last-child .filters__leased_type {
      border-radius: 0 14px 14px 0;
    }
  }

  &__last-floor {
    font-size: 15px;
    color: #2f1f19;
  }

  &__floor {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    padding-right: 28px;
    outline: none;

    @include arrow(10px, $colour-heathered-grey, 2px) {
      margin-left: auto;
      transform: rotate(45deg);
      margin-right: -18px;
    }
  }
}
