.white-header {
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0 15px;
  border-bottom: 1px solid #eae5e1;

  &--fixed {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 95;

    ~ .placeholder-st {
      margin-top: 25px;
    }
  }

  &--center {
    position: relative;
    justify-content: center;
  }

  &--main {
    justify-content: space-between;
    border-bottom: 0;
  }

  &--hidden {
    visibility: hidden;
  }

  &__title {
    color: #2f1f19;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  &__webview-btn {
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 8px;
    padding: 0;

    i {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      display: block;
      flex: 1;
    }
  }
}
